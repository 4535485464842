/* Autogenerated file. Do not edit manually. */

import type { BytesLike, JsonAbi } from 'fuels';

export const generationDate = 1728253389401;
// biome-ignore lint: Autogenerated file
export const abi: JsonAbi = {
  programType: 'predicate',
  specVersion: '1',
  encodingVersion: '1',
  concreteTypes: [
    {
      type: 'b256',
      concreteTypeId:
        '7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b',
    },
    {
      type: 'bool',
      concreteTypeId:
        'b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903',
    },
    {
      type: 'u64',
      concreteTypeId:
        '1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0',
    },
  ],
  metadataTypes: [],
  functions: [
    {
      inputs: [
        {
          name: 'witness_index',
          concreteTypeId:
            '1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0',
        },
      ],
      name: 'main',
      output:
        'b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903',
      attributes: null,
    },
  ],
  loggedTypes: [],
  messagesTypes: [],
  configurables: [
    {
      name: 'SIGNER',
      concreteTypeId:
        '7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b',
      offset: 88,
    },
  ],
};
export const bin: BytesLike = new Uint8Array([
  26, 64, 48, 0, 80, 65, 0, 48, 26, 68, 80, 0, 186, 73, 0, 0, 50, 64, 4, 129,
  80, 65, 0, 32, 93, 73, 0, 0, 80, 65, 0, 8, 50, 64, 4, 130, 32, 69, 19, 0, 82,
  69, 16, 4, 74, 68, 0, 0, 197, 141, 8, 223, 13, 49, 223, 170, 173, 213, 125,
  233, 240, 77, 137, 183, 171, 200, 139, 40, 162, 244, 95, 82, 44, 197, 193,
  253, 141, 166, 121, 120, 0, 0, 0, 0, 0, 0, 0, 136, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25, 69,
  116, 104, 101, 114, 101, 117, 109, 32, 83, 105, 103, 110, 101, 100, 32, 77,
  101, 115, 115, 97, 103, 101, 58, 10, 51, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  204, 204, 204, 204, 204, 204, 0, 2, 0, 0, 0, 0, 0, 0, 11, 92, 0, 0, 0, 0, 0,
  0, 11, 116, 0, 0, 0, 0, 0, 0, 11, 108, 0, 0, 0, 0, 0, 0, 6, 128,
]);
