const delegateRegistryAbi = [
  { inputs: [], name: "MulticallFailed", type: "error" },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      { indexed: false, internalType: "bytes32", name: "rights", type: "bytes32" },
      { indexed: false, internalType: "bool", name: "enable", type: "bool" },
    ],
    name: "DelegateAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      { indexed: true, internalType: "address", name: "contract_", type: "address" },
      { indexed: false, internalType: "bytes32", name: "rights", type: "bytes32" },
      { indexed: false, internalType: "bool", name: "enable", type: "bool" },
    ],
    name: "DelegateContract",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      { indexed: true, internalType: "address", name: "contract_", type: "address" },
      { indexed: false, internalType: "uint256", name: "tokenId", type: "uint256" },
      { indexed: false, internalType: "bytes32", name: "rights", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "DelegateERC1155",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      { indexed: true, internalType: "address", name: "contract_", type: "address" },
      { indexed: false, internalType: "bytes32", name: "rights", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "DelegateERC20",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      { indexed: true, internalType: "address", name: "contract_", type: "address" },
      { indexed: false, internalType: "uint256", name: "tokenId", type: "uint256" },
      { indexed: false, internalType: "bytes32", name: "rights", type: "bytes32" },
      { indexed: false, internalType: "bool", name: "enable", type: "bool" },
    ],
    name: "DelegateERC721",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
    ],
    name: "checkDelegateForAll",
    outputs: [{ internalType: "bool", name: "valid", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
    ],
    name: "checkDelegateForContract",
    outputs: [{ internalType: "bool", name: "valid", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
    ],
    name: "checkDelegateForERC1155",
    outputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
    ],
    name: "checkDelegateForERC20",
    outputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
    ],
    name: "checkDelegateForERC721",
    outputs: [{ internalType: "bool", name: "valid", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
      { internalType: "bool", name: "enable", type: "bool" },
    ],
    name: "delegateAll",
    outputs: [{ internalType: "bytes32", name: "hash", type: "bytes32" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
      { internalType: "bool", name: "enable", type: "bool" },
    ],
    name: "delegateContract",
    outputs: [{ internalType: "bytes32", name: "hash", type: "bytes32" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "delegateERC1155",
    outputs: [{ internalType: "bytes32", name: "hash", type: "bytes32" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "delegateERC20",
    outputs: [{ internalType: "bytes32", name: "hash", type: "bytes32" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "address", name: "contract_", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes32", name: "rights", type: "bytes32" },
      { internalType: "bool", name: "enable", type: "bool" },
    ],
    name: "delegateERC721",
    outputs: [{ internalType: "bytes32", name: "hash", type: "bytes32" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32[]", name: "hashes", type: "bytes32[]" }],
    name: "getDelegationsFromHashes",
    outputs: [
      {
        components: [
          {
            internalType: "enum IDelegateRegistry.DelegationType",
            name: "type_",
            type: "uint8",
          },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "address", name: "from", type: "address" },
          { internalType: "bytes32", name: "rights", type: "bytes32" },
          { internalType: "address", name: "contract_", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        internalType: "struct IDelegateRegistry.Delegation[]",
        name: "delegations_",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "to", type: "address" }],
    name: "getIncomingDelegationHashes",
    outputs: [
      { internalType: "bytes32[]", name: "delegationHashes", type: "bytes32[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "to", type: "address" }],
    name: "getIncomingDelegations",
    outputs: [
      {
        components: [
          {
            internalType: "enum IDelegateRegistry.DelegationType",
            name: "type_",
            type: "uint8",
          },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "address", name: "from", type: "address" },
          { internalType: "bytes32", name: "rights", type: "bytes32" },
          { internalType: "address", name: "contract_", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        internalType: "struct IDelegateRegistry.Delegation[]",
        name: "delegations_",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "from", type: "address" }],
    name: "getOutgoingDelegationHashes",
    outputs: [
      { internalType: "bytes32[]", name: "delegationHashes", type: "bytes32[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "from", type: "address" }],
    name: "getOutgoingDelegations",
    outputs: [
      {
        components: [
          {
            internalType: "enum IDelegateRegistry.DelegationType",
            name: "type_",
            type: "uint8",
          },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "address", name: "from", type: "address" },
          { internalType: "bytes32", name: "rights", type: "bytes32" },
          { internalType: "address", name: "contract_", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        internalType: "struct IDelegateRegistry.Delegation[]",
        name: "delegations_",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes[]", name: "data", type: "bytes[]" }],
    name: "multicall",
    outputs: [{ internalType: "bytes[]", name: "results", type: "bytes[]" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "location", type: "bytes32" }],
    name: "readSlot",
    outputs: [{ internalType: "bytes32", name: "contents", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32[]", name: "locations", type: "bytes32[]" }],
    name: "readSlots",
    outputs: [{ internalType: "bytes32[]", name: "contents", type: "bytes32[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "sweep",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const

export default delegateRegistryAbi
